class MyMouseXy {
  // 鼠标左键按下的坐标  也是画线段的开始坐标
  static x;
  static y;
  //鼠标移动后的坐标  也是画线段的末端的坐标
  static x2;
  static y2;
  //鼠标左键是否弹起
  static ifmouseup = false;
}

class MyLine {
  x2;
  y2;
  //构造方法设置第一个点
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
  //更新 设置第二给点
  LineUpdata = function (x, y) {
    this.x2 = x;
    this.y2 = y;
  };

  //绘制
  LineRraw = function (ctx) {
    ctx.beginPath();
    ctx.moveTo(this.x, this.y); //第一个点
    ctx.lineTo(this.x2, this.y2); //点三个

    ctx.stroke(); //开始绘制(把前面点连接)
    //这段线段绘制完成后 把当前线段的末尾点坐标赋值给下一段的起始点
    MyMouseXy.x = this.x2;
    MyMouseXy.y = this.y2;
  };
}

class MyRraw {
  static raew;
  //绘制直线的方法Rae
  static Rae = function (ctx) {
    //通过监听器连续执行方法  把鼠标左键的点击时候的位置xy赋值给线段首点的坐标xy,再把鼠标移动后的点x2y2坐标赋值给线段末端点的坐标x2,y2 两点确定一条直线
    this.raew = setInterval(() => {
      //创建线段 把鼠标的点传入
      let myline = new MyLine(MyMouseXy.x, MyMouseXy.y);
      myline.LineUpdata(MyMouseXy.x2, MyMouseXy.y2);
      //当鼠标左键弹起时候停止绘制
      if (MyMouseXy.ifmouseup != true) {
        myline.LineRraw(ctx);
      }
    });
  };
}
export default { MyLine, MyRraw, MyMouseXy };
