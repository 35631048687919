<template>
  <div id="app">
    <!-- <home-page /> -->
    <jssip-page />
    <!-- <canvasHTML /> -->
  </div>
</template>

<script>
// import homePage from "./views/homePage.vue";
import jssipPage from "./views/jssipPage.vue";
import canvasHTML from "./views/canvasHTML.vue";
import JsSIP from "jssip";

export default {
  name: "App",
  components: {
    jssipPage,
    canvasHTML,
  },
  data() {
    return {};
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}
#app > div {
  flex: 1;
  padding: 0 10px;
}
</style>
