<template>
  <div>
    <h2>jssip视频通话</h2>
    <div class="wrap">
      <el-form :model="formInfo2" label-width="100px">
        <el-form-item label="服务地址：">
          <el-input type="text" v-model="formInfo2.server"></el-input>
        </el-form-item>
        <el-form-item label="端口号：">
          <el-input type="text" v-model="formInfo2.port"></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input type="text" v-model="formInfo2.password"></el-input>
        </el-form-item>
        <el-form-item label="房间号：">
          <el-input type="text" v-model="formInfo2.room"></el-input>
        </el-form-item>
        <el-form-item label="分机号：">
          <el-input type="text" v-model="formInfo2.main"></el-input>
        </el-form-item>
        <el-form-item label="发送给">
          <el-input type="text" v-model="formInfo2.to"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button v-if="!show" type="primary" @click="video">video</el-button>
      </div>
      <div>
        <!-- <el-button type="primary" @click="show = true">展开</el-button> -->
      </div>
      <div v-if="show">
        <el-button type="primary" @click="pause">{{
          playFlag ? "暂停" : "继续"
        }}</el-button>
        <el-button type="primary" @click="onPhoto">拍照</el-button>
        <el-button type="primary" :disabled="isRecord" @click="onRecordStart">{{
          isRecord ? "正在录像中..." : "开始录制"
        }}</el-button>
        <el-button type="primary" @click="onRecordEnd">结束录制</el-button>
        <el-button type="primary" @click="onShareStart">共享屏幕</el-button>
        <el-button type="primary" @click="onShareEnd">取消共享</el-button>
        <el-button type="primary" @click="oncanvas">共享canvas</el-button>
        <el-button type="primary" @click="closecanvas"
          >关闭共享canvas</el-button
        >
        <el-button type="primary" @click="cancel">取消</el-button>
      </div>
      <div v-if="show || isShare">
        <div class="message_style">
          <el-input type="text" v-model="inputMessage">
            <el-button slot="append" type="primary" @click="send"
              >发送</el-button
            >
          </el-input>
        </div>
        <video
          v-loading="loading"
          id="my-video"
          playsinline
          muted
          width="400"
          height="300"
        ></video>

        <VueDragResize
          v-loading="loading"
          :isActive="isActive"
          :parent="true"
          :w="850"
          :h="500"
          :x="left"
          :y="top"
          v-on:resizing="onResize"
          v-on:dragging="onResize"
          @deactivated="onDeactivated"
          @activated="onActivated"
        >
          <video
            id="remote-video"
            playsinline
            muted
            :width="width"
            :height="height"
          ></video>
        </VueDragResize>
      </div>
    </div>

    <VueDragResize
      v-show="canvasshow"
      :w="500"
      :h="500"
      :isDraggable="isDraggable"
    >
      <div>
        <span @click="open">开启拖拽</span>
        <span @click="close"> 关闭拖拽</span>
        <span @click="eliminate"> 清除</span>
        <el-color-picker
          v-model="color"
          show-alpha
          :predefine="predefineColors"
          @change="changeclolor"
        >
        </el-color-picker>
      </div>
      <Canvas
        id="canvashtml"
        width="850"
        height="500"
        @mousedown="onmousedown"
        @mousemove="onmousemove"
        @mouseup="onmouseup"
      ></Canvas>
    </VueDragResize>
  </div>
</template>
<script>
import JsSIP from "jssip";
import VueDragResize from "vue-drag-resize";
import { downloadFileByBase64 } from "../utils/common.js";
import canvasjs from "./canvas.js";

const winWidth = window.innerWidth;
export default {
  components: { VueDragResize },
  data() {
    return {
      color: "rgba(255, 69, 0, 0.68)",
      predefineColors: ["#ff4500", "#ff8c00", "#ffd700", "#90ee90", "#00ced1"],
      arr: {},
      isDraggable: true,
      canvasshow: false,
      formInfo2: {
        server: "sip.woyoucloud.cn",
        port: "7443",
        password: "826890",
        room: "3501",
        main: "805",
        to: "806",
      },
      isActive: false,
      width: 850,
      height: 500,
      top: 180,
      left: (winWidth - 850) / 2,
      show: false, // 是否打开视频
      loading: false, // 视频连接成功
      playFlag: false, // 视频暂停/继续
      userAgent: null,
      inputMessage: "",
      mediaRecorder: {},
      chunks: [],
      isRecord: false,
      isShare: false,
      shareStream: null,
      currentSession: null,
      currentConnection: null,
    };
  },
  methods: {
    // 选择颜色
    changeclolor(val) {
      this.color = val;
    },
    // 画板清除
    eliminate() {
      var canvas = document.getElementById("canvashtml");
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },

    // 画板鼠标按下
    onmousedown(event) {
      var canvas = document.getElementById("canvashtml");
      var ctx = canvas.getContext("2d");
      if (this.isDraggable) {
        return;
      }
      canvasjs.MyMouseXy.ifmouseup = false;
      canvasjs.MyMouseXy.x = event.offsetX;
      canvasjs.MyMouseXy.y = event.offsetY;
      ctx.strokeStyle = this.color; //设颜色
      canvasjs.MyRraw.Rae(ctx);
    },

    // 画板鼠标经过
    onmousemove(XY) {
      canvasjs.MyMouseXy.x2 = XY.offsetX;
      canvasjs.MyMouseXy.y2 = XY.offsetY;
    },
    // 画板鼠标抬起
    onmouseup() {
      canvasjs.MyMouseXy.ifmouseup = true;
    },
    // 打开拖拽画布
    open() {
      this.isDraggable = true;
    },
    // 关闭拖拽画布
    close() {
      this.isDraggable = false;
    },

    // 关闭共享画板
    closecanvas() {
      if (!this.canvasshow) {
        return;
      }
      this.canvasshow = false;
      this.isDraggable = false;
      this.endDesktopSharing();
      const remoteVideo = document.getElementById("remote-video");
      remoteVideo.srcObject = null;
      remoteVideo.pause();
      this.isShare = false;
    },

    // 打开共享画板
    oncanvas() {
      let _that = this;
      var myCanvas = document.querySelector("canvas");
      // 获取上下文,绘制工具箱
      var ctx = myCanvas.getContext("2d");
      // 绘制直线（轨迹，绘制路径）
      ctx.fillRect(0, 0, 850, 500);
      // 描边
      ctx.stroke();

      this.canvasshow = true;
      this.isDraggable = true;
      let peerConnection = _that.currentConnection;
      let disStream = myCanvas.captureStream(25);
      _that.shareStream = disStream;
      peerConnection.getSenders().forEach((sender) => {
        if (sender.track) {
          if (sender.track.kind == "video") {
            sender.replaceTrack(disStream.getVideoTracks()[0]);
          }
          if (sender.track.kind == "audio") {
            sender.replaceTrack(disStream.getAudioTracks()[0]);
          }
        }
      });
      const remoteVideo = document.getElementById("remote-video");
      remoteVideo.srcObject = disStream;
      remoteVideo.play();
      // 监听屏幕共享结束
      disStream.oninactive = () => {
        console.log("监听屏幕共享结束");
        _that.switchLocalCamera(peerConnection);
      };
    },
    // 获取canvas视频流
    canvascaptureStream(canvas) {
      return canvas.captureStream(25);
    },
    onResize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    onDeactivated() {
      this.isActive = false;
    },
    onActivated() {
      this.isActive = true;
    },

    // 点击视频连接
    video() {
      JsSIP.debug.enable("JsSIP:*");
      let _that = this;
      _that.show = true;
      _that.loading = true;
      const socket = new JsSIP.WebSocketInterface(
        `wss://${this.formInfo2.server}:${this.formInfo2.port}`
      );
      const configuration = {
        sockets: [socket],
        uri: `sip:${this.formInfo2.main}@${this.formInfo2.server}`, // 'sip:1007@192.168.1.83',
        password: this.formInfo2.password,
        session_timers: false,
      };
      const userAgent = new JsSIP.UA(configuration);

      userAgent.start();
      userAgent.on("newRTCSession", function (res) {
        console.log("newRTCSession::", res);
        let { session, originator } = res;
        console.log(session, originator, "session, originator ");
        // 远程来电
        if (originator === "remote") {
          // 处理接听逻辑
          _that.handleAnswerWebRTCSession(session);
        } else if (originator === "local") {
          // 处理呼叫逻辑
          _that.handleCallWebRTCSession(session);
        }
      });

      const eventHandlers = {
        connecting: function (e) {
          _that.$message({
            message: "连接中",
            type: "warning",
          });
          console.log("连接中", e);
        },
        progress: function (e) {
          console.log("call is in progress22222222");
        },
        failed: function (e) {
          _that.$message({
            message: "连接失败",
            type: "warning",
          });
          console.log("连接失败", e);
        },
        confirmed: function (e) {
          _that.$message({
            message: "接通",
            type: "warning",
          });
          console.log("接通", e);
          _that.loading = false;
        },
        ended: function (e) {
          _that.$message({
            message: "结束",
            type: "warning",
          });
          console.log("结束 " + e);
        },
      };
      const options = {
        eventHandlers: eventHandlers,
        mediaConstraints: { audio: true, video: true },
        pcConfig: {
          iceServers: [
            { urls: ["stun:stun.woyoucloud.cn"] },

            {
              urls: "turn:turn.woyoucloud.cn",
              username: "default",
              credential: "default1",
            },
          ],
        },
      };
      userAgent.call(
        `sip:${this.formInfo2.room}@${this.formInfo2.server}`,
        options
      ); // 'sip:3501@192.168.1.83'
      _that.userAgent = userAgent;
    },

    handleAnswerWebRTCSession(session) {
      let { connection } = session;
      this.currentSession = session;
      this.currentConnection = connection;
      // 来电-被接听了
      session.on("accepted", () => {
        this.handleStreamsSrcObject(connection);
      });
      session.on("peerconnection", () => {});
      // 来电=>自定义来电弹窗，让用户选择接听和挂断
      session.on("progress", () => {});
      // 挂断-来电已挂断
      session.on("ended", () => {});
      // 当会话无法建立时触发
      session.on("failed", () => {});
    },

    handleCallWebRTCSession(session) {
      let { connection } = session;
      this.currentSession = session;
      this.currentConnection = connection;
      session.on("progress", () => {
        // 呼叫中，响铃中
      });
      session.on("confirmed", () => {
        console.log("confirmedconfirmedconfirmed");
        this.handleStreamsSrcObject(connection);
      });
    },

    handleStreamsSrcObject(connection) {
      if (connection.getRemoteStreams().length > 0) {
        // 获取远程媒体流
        let srcObject = connection.getRemoteStreams()[0];
        console.log(srcObject, "srcObject");
        const remoteVideo = document.getElementById("remote-video");
        remoteVideo.srcObject = srcObject;
        remoteVideo.play();
        this.playFlag = true;
      }

      if (connection.getLocalStreams().length > 0) {
        // 获取本地媒体流
        let srcObject = connection.getLocalStreams()[0];
        const localVideo = document.getElementById("my-video");
        localVideo.srcObject = srcObject;
        localVideo.play();
        this.playFlag = true;
      }
    },

    pause() {
      const localVideo = document.getElementById("my-video");
      const remoteVideo = document.getElementById("remote-video");
      if (this.playFlag) {
        localVideo.pause();
        remoteVideo.pause();
        this.playFlag = false;
      } else {
        localVideo.play();
        remoteVideo.play();
        this.playFlag = true;
      }
    },

    // 点击取消
    async cancel() {
      this.userAgent.unregister({ all: true });
      await this.userAgent.stop();
      this.playFlag = false;
      this.show = false;
      this.loading = false;
      this.top = 180;
      this.left = (winWidth - 850) / 2;
    },

    // 点击发送
    send() {
      if (this.inputMessage.replace(/^\s*/, "").length == 0) {
        this.$message({
          message: "请输入要发送的内容",
          type: "warning",
        });
        return;
      }
      const eventHandlers = {
        succeeded: function (e) {
          console.log("successed:", "发送成功", e);
          // e.on('IncomingMessage', function(data) {
          //   console.log("IncomingMessage:", data)
          // })
        },
        failed: function (e) {
          console.log("failed:", "发送失败", e);
        },
      };
      const options = {
        eventHandlers: eventHandlers,
      };
      // this.userAgent.sendMessage(`sip:${this.formInfo2.room}@${this.formInfo2.server}`, this.inputMessage, options);
      this.userAgent.sendMessage(
        `sip:${this.formInfo2.to}@${this.formInfo2.server}`,
        this.inputMessage,
        options
      );

      this.userAgent.on("newMessage", function (data) {
        debugger;
        console.log("newMessage::", data);
        if (data.originator == "local") {
          console.info(
            "onNewMessage , OutgoingRequest - ",
            data.request,
            data.request.body
          );
        } else {
          console.info("onNewMessage , IncomingRequest - ", data.request);
        }
      });
    },

    // 拍照
    onPhoto() {
      var canvas = document.createElement("canvas");
      canvas.setAttribute("id", "video-canvas");
      canvas.setAttribute("display", "display: none;");

      var ctx = canvas.getContext("2d");
      var video = document.getElementById("remote-video");
      // 设置canvas的宽高与视频的宽高一致
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // 关闭图像平滑处理
      ctx.imageSmoothingEnabled = false;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      // var imageData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height);
      // console.log(imageData)
      var base64ImageData = canvas.toDataURL();
      console.log(base64ImageData);
      downloadFileByBase64(base64ImageData, "jssip");
    },

    onRecordStart() {
      const remoteVideo = document.getElementById("remote-video");
      let stream = remoteVideo.srcObject;
      let mediaRecorder = new MediaRecorder(stream);
      console.log(mediaRecorder);
      let chunks = [];
      mediaRecorder.ondataavailable = function (e) {
        mediaRecorder.blobs.push(e.data);
        chunks.push(e.data);
      };
      mediaRecorder.blobs = [];
      mediaRecorder.start(100);
      this.isRecord = true;
      this.mediaRecorder = mediaRecorder;
      this.chunks = chunks;
    },

    // 结束录制
    onRecordEnd() {
      let recorderFile = new Blob(this.chunks, {
        type: this.mediaRecorder.mimeType,
      });
      this.chunks = [];
      var url = URL.createObjectURL(recorderFile);
      var a = document.createElement("a");
      a.href = url;
      a.download = "video.webm";
      a.click();
      this.isRecord = false;
    },

    // 屏幕共享
    onShareStart() {
      let _that = this;
      let peerConnection = _that.currentConnection;
      navigator.mediaDevices
        .getDisplayMedia({ video: true, audio: true })
        .then((disStream) => {
          console.log(disStream, "disStreamdisStream");
          let srcObject = disStream;
          _that.shareStream = disStream;
          peerConnection.getSenders().forEach((sender) => {
            if (sender.track) {
              if (sender.track.kind == "video") {
                sender.replaceTrack(disStream.getVideoTracks()[0]);
              }
              if (sender.track.kind == "audio") {
                sender.replaceTrack(disStream.getAudioTracks()[0]);
              }
            }
          });
          const remoteVideo = document.getElementById("remote-video");
          console.log(remoteVideo.srcObject, "remoteVideo.play()");

          remoteVideo.srcObject = srcObject;
          console.log(remoteVideo.srcObject, "srcObject.play()");

          remoteVideo.play();
          // 监听屏幕共享结束
          disStream.oninactive = () => {
            console.log("监听屏幕共享结束");
            _that.switchLocalCamera(peerConnection);
          };
        })
        .catch((error) => {
          console.error("屏幕共享失败，失败原因：", error);
        });
    },

    // 手动结束屏幕共享
    endDesktopSharing() {
      const desktopMediaStream = this.shareStream;
      if (desktopMediaStream === null) {
        return;
      }
      desktopMediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      this.shareStream = null;
    },

    // 切换为本地摄像头
    switchLocalCamera(peerConnection) {
      let _that = this;
      let localStreams = _that.currentSession.connection.getLocalStreams();
      console.log(localStreams, "localStreamslocalStreams");
      if (localStreams.length) {
        let localVideoTracks = localStreams[0].getVideoTracks();
        let localAudioTracks = localStreams[0].getAudioTracks();
        peerConnection.getSenders().forEach((sender) => {
          if (sender.track && sender.track.kind == "video") {
            if (localVideoTracks.length) {
              sender.replaceTrack(localVideoTracks[0]);
            }
          }
          if (sender.track && sender.track.kind == "audio") {
            if (localAudioTracks) {
              sender.replaceTrack(localAudioTracks[0]);
            }
          }
        });
        const remoteVideo = document.getElementById("remote-video");
        remoteVideo.srcObject = peerConnection.getRemoteStreams()[0];
        remoteVideo.play();
      }
    },

    // 取消共享
    onShareEnd() {
      this.endDesktopSharing();
      const remoteVideo = document.getElementById("remote-video");
      remoteVideo.srcObject = null;
      remoteVideo.pause();
      this.isShare = false;
    },
  },
};
</script>

<style>
.wrap {
  width: 100%;
  margin: 0 auto;
}
</style>
