<template>
  <div>
    <div>
      <span @click="kaqi">开启拖拽</span>
      <span @click="guanbi"> 关闭拖拽</span>
      <span @click="eliminate"> 清除</span>
      <el-color-picker
        v-model="color"
        show-alpha
        :predefine="predefineColors"
        @change="changeclolor"
      >
      </el-color-picker>
    </div>
    <Canvas
      id="canvashtml"
      width="500"
      height="500"
      @mousedown="onmousedown"
      @mousemove="onmousemove"
      @mouseup="onmouseup"
    ></Canvas>
  </div>
</template>

<script>
import canvasjs from "./canvas.js";
export default {
  data() {
    return {
      color: "rgba(255, 69, 0, 0.68)",
      predefineColors: ["#ff4500", "#ff8c00", "#ffd700", "#90ee90", "#00ced1"],
      arr: {},
      painting: false,
    };
  },
  mounted() {},

  methods: {
    // 选择颜色
    changeclolor(val) {
      this.color = val;
    },
    kaqi() {
      this.$emit("open");
    },
    guanbi() {
      this.$emit("close");
    },
    // 清除
    eliminate() {
      var canvas = document.getElementById("canvashtml");
      var ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },

    // 鼠标按下
    onmousedown(event) {
      var canvas = document.getElementById("canvashtml");
      var ctx = canvas.getContext("2d");
      if (this.$parent.isDraggable) {
        return;
      }
      canvasjs.MyMouseXy.ifmouseup = false;
      canvasjs.MyMouseXy.x = event.offsetX;
      canvasjs.MyMouseXy.y = event.offsetY;
      ctx.strokeStyle = this.color; //设颜色
      canvasjs.MyRraw.Rae(ctx);
    },
    // 鼠标经过
    onmousemove(XY) {
      canvasjs.MyMouseXy.x2 = XY.offsetX;
      canvasjs.MyMouseXy.y2 = XY.offsetY;
    },
    onmouseup() {
      canvasjs.MyMouseXy.ifmouseup = true;
    },
  },
};
</script>

<style>
#canvashtml {
  background: #fff;
  border: 2px solid #000;
}
</style>
