const downloadFileByBase64 = (base64, name) => {
  function dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
  }
  
  function downloadFile(url,name){
      var a = document.createElement("a") //新建一个a链接
      a.setAttribute("href",url) // a链接的url为图片的url
      a.setAttribute("download",name)
      a.setAttribute("target","_blank")
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);  
      a.dispatchEvent(clickEvent);
  }

  var myBlob = dataURLtoBlob(base64);
  var myUrl = URL.createObjectURL(myBlob); //创建图片的临时url
  downloadFile(myUrl,name)
}

export {
  downloadFileByBase64
}